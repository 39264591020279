import bootbox from "bootbox";

import Routing from "../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
Routing.setRoutingData(require("../../../js/fos_js_routes.json"));

// require jQuery normally
const $ = require("jquery");
global.$ = global.jQuery = $;

var App = {
    /***********************
     * Init element / Libs
     **********************/
    initFormTableSubmit: function() {
        $(".form-table-submit").on("click", function(e) {
            e.preventDefault();
            $button = $(this);

            if ($button.hasClass("form-table-group-toggle")) {
                $(".group-addCustomer-layout").toggle();
                return;
            }

            if ($("." + $button.attr("data-check") + ":checked").length == 0) {
                modalAlert("Veuillez effectuer une sélection");
            } else {
                $formTable = $("#" + $button.attr("data-form"));
                $formTable.attr("action", $button.attr("data-action"));

                if ($button.hasClass("form-table-delete")) {
                    modalConfirmDelete(function() {
                        $formTable.submit();
                    });
                } else {
                    $formTable.submit();
                }
            }
        });
    },

    initActionMultiple: function() {
        $(".action-multiple").each(function() {
            $(this).on("click", function(event) {
                event.preventDefault();
                $(this)
                    .parents("form")
                    .attr("action", $(this).attr("href"))
                    .submit();
            });
        });
    },

    initCheckAll: function() {
        $(".check-all").change(function() {
            var isChecked = $(this).is(":checked");

            $("." + $(this).data("target")).each(function() {
                $(this).prop("checked", isChecked);
            });
        });
    },

    initAutocomplete: function() {
        $(".autocomplete").each(function() {
            var field = $(this);
            var _id = field.data("target");
            var list = $("#" + _id + "_list");
            var originValue = field.val();

            if (false == field.data("required")) {
                field.removeAttr("required");
            }

            list.on(
                {
                    "click.item-autocomplete": function(e) {
                        $(this)
                            .closest("li")
                            .fadeOut(300, function() {
                                $(this).remove();
                            });
                        e.preventDefault();
                        e.stopPropagation();
                    }
                },
                "a"
            );

            field.on("change", function(e) {
                if ("" == field.val()) {
                    $("#" + _id).val("");
                }
            });

            field.autocomplete({
                deferRequestBy: field.data("delay"),
                lookupLimit: field.data("max-items"),
                minChars: field.data("min-chars"),
                serviceUrl: field.data("url"),
                onSelect: function(suggestion) {
                    if (false == field.data("multiple")) {
                        $("#" + field.data("target")).val(suggestion.data);
                        originValue = suggestion.value;
                    } else {
                        var formName = field.closest("form").prop("name");

                        if (list.length) {
                            var li = list.find("#" + _id + "_" + suggestion.data);

                            if (!li.length) {
                                var name = (formName ? _id.replace(formName + "_", "") : _id).split("_");

                                if (formName) {
                                    name.unshift(formName);
                                }

                                name = (name.length > 1 ? name.shift() + "[" + name.join("][") + "]" : name.join()) + "[]";

                                li = $($("#" + _id).data("prototype"));
                                li.data("value", suggestion.data)
                                    .find("input:hidden")
                                    .val(suggestion.data)
                                    .attr("id", _id + "_" + suggestion.data)
                                    .attr("name", name)
                                    .end()
                                    .find(".autocomplete-item")
                                    .text(suggestion.value)
                                    .end()
                                    .appendTo(list);
                            }

                            field.val("");
                        }
                    }
                }
            });
        });
    },

    initDatePicker: function() {
        $(".datepicker").datepicker({
            format: "dd/mm/yyyy",
            language: "fr",
            orientation: "bottom"
        });
    },

    initSideBar: function() {
        $('.navbar-sidenav [data-toggle="tooltip"]').tooltip({
            template:
                '<div class="tooltip navbar-sidenav-tooltip" role="tooltip" style="pointer-events: none;"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        });

        // Toggle the side navigation
        $("#sidenavToggler").click(function(e) {
            e.preventDefault();
            $("body").toggleClass("sidenav-toggled");
            $(".navbar-sidenav .nav-link-collapse").addClass("collapsed");
            $(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").removeClass("show");
        });

        // Force the toggled class to be removed when a collapsible nav link is clicked
        $(".navbar-sidenav .nav-link-collapse").click(function(e) {
            e.preventDefault();
            $("body").removeClass("sidenav-toggled");
        });
    },

    initCheckBox: function() {
        $(".check-all").change(function() {
            $checkAll = $(this);
            $("." + $checkAll.attr("data-check")).prop("checked", $checkAll.prop("checked"));
        });
    },
    initBootBoxModal: function() {
        $('[data-confirm="confirm-delete"]').each(function(index, item) {
            $(item).on("click", function() {
                App.modalConfirmDelete(function() {
                    App.redirect($(item).attr("data-href"));
                });
            });
        });
    },

    /***********************
     * Custom Bootbox Modal functions
     **********************/

    redirect: function(url) {
        window.location.href = url;
    },

    modalConfirm: function(params) {
        var defaultParams = {
            title: "Confirmation",
            message: "Êtes-vous sûr ?",
            buttons: {
                cancel: {
                    label: "Non"
                },
                confirm: {
                    label: "Oui"
                }
            },
            callback: function(result) {}
        };
        if (undefined == params) {
            params = defaultParams;
        }
        bootbox.confirm(params);
    },

    modalAlert: function(message, title) {
        if (undefined == title) {
            title = "Attention";
        }

        bootbox.alert({
            message: message,
            title: title
        });
    },

    modalConfirmDelete: function(callback) {
        var params = {
            title: "Supprimer",
            message: "Êtes-vous sûr de vouloir supprimer ?",
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Annuler'
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Oui, supprimer'
                }
            },
            callback: function(result) {
                if (result == true) {
                    callback();
                }
            }
        };
        this.modalConfirm(params);
    },

    initSelectPicker: function() {
        $(".selectpicker").selectpicker();
    }
};

// Export APP functions
global.App = App;

$(document).ready(function() {
    App.initActionMultiple();
    App.initAutocomplete();
    App.initCheckAll();
    App.initDatePicker();
    App.initCheckBox();
    App.initBootBoxModal();
    App.initSideBar();
    App.initFormTableSubmit();
    App.initSelectPicker();
});

/***********************
 * ScrollTop
 **********************/

// Scroll to top button appear
$(document).scroll(function() {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
        $(".scroll-to-top").fadeIn();
    } else {
        $(".scroll-to-top").fadeOut();
    }
});
// Configure tooltips globally
$('[data-toggle="tooltip"]').tooltip();
// Smooth scrolling using jQuery easing
$(document).on("click", "a.scroll-to-top", function(event) {
    var $anchor = $(this);
    $("html, body")
        .stop()
        .animate(
            {
                scrollTop: $($anchor.attr("href")).offset().top
            },
            1000,
            "easeInOutExpo"
        );
    event.preventDefault();
});

/*MODAL HOME
document.getElementById('boutonDeb').addEventListener("click",
    function () {
        var monElement2=document.getElementById('modal_infosAnnonce');
        monElement2.style.opacity="0";
});

document.getElementById('close').addEventListener("click",
    function () {
        var el = document.getElementsByClassName("modal-backdrop")[1];
        el.style.opacity="0";
});
*/

/*document.getElementById('boutonDeb').addEventListener("click",
    function () {
        document.getElementById('testmod').style.display = "none";
    
});*/
