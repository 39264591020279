import React from "react";
import { render } from "react-dom";
import CookieConsent from "./components/CookieConsent";

import Translator from "bazinga-translator";
Translator.fromJSON(require("../../../../../js/translations/fr.json"));

/**
 * Rendering component CookieConsent
 */
const cookieConsent = document.getElementById("component-cookie-consent");

if (cookieConsent) {
    render(<CookieConsent />, cookieConsent);
}
