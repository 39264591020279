// TUTO : https://www.npmjs.com/package/react-cookie-consent

import React from "react";
import CC from "react-cookie-consent";
import Translator from "bazinga-translator";

export default class CookieConsent extends React.Component {
    render() {
        return (
            <CC
                buttonStyle={{ backgroundColor: "#7f181a", color: "white" }}
                buttonText={Translator.trans("front.cookie.accept")}
                cookieName="cookieConsentHubDebarras"
                expires={30}
            >
                {Translator.trans("front.cookie.message")}
            </CC>
        );
    }
}
